import React from "react"
import styled from "styled-components"

const Widget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 576px) {
    margin-top: 1em;
  }
`

const WidgetInfo = styled.div`
  margin-left: 1em;
`

const StarIcon = styled.span``

const RatingText = styled.h5`
  font-size: 1em;
`

const Paragraph = styled.p`
  font-size: 0.8em;
  color: var(--text-dark2);
`

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--text-dark2);
`

const ClutchWidget = () => {
  return (
    <StyledLink
      href="https://clutch.co/profile/apm-software#summary"
      target="_blank"
    >
      {" "}
      <Widget>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="141"
          height="41"
          viewBox="0 0 141 41"
        >
          <g fill-rule="nonzero" fill="none">
            <path
              fill="#17313B"
              d="M35.41 0h6.522v40.068h-6.523zM65.27 28.333c0 6.193-5.07 6.756-6.76 6.756-3.944 0-4.508-3.66-4.508-5.63V13.977h-6.48v15.2c0 3.66 1.128 7.038 3.381 9.008C52.875 40.155 55.411 41 58.51 41c2.253 0 5.07-.563 6.76-2.252V41h6.48V13.977h-6.48v14.356zM84.452 3.727h-6.768v9.959h-5.002v6.26h5.002V41h6.768V19.945h5.003v-6.26h-5.003zM109.323 32.072c-1.393 1.103-3.342 1.93-5.291 1.93-4.456 0-7.52-3.31-7.52-7.72 0-4.413 3.064-7.446 7.52-7.446 1.949 0 3.898.552 5.29 1.93l.836.827 4.456-4.136-1.114-.827a14.359 14.359 0 0 0-9.468-3.585c-7.798 0-13.646 5.791-13.646 13.512 0 7.72 5.848 13.511 13.646 13.511 3.62 0 6.961-1.379 9.468-3.584l1.114-.828-4.456-4.412-.835.828zM137.91 15.237c-1.958-1.975-3.915-2.822-6.99-2.822-2.236 0-4.472.565-6.15 2.258V0h-6.43v40.068h6.43V25.113c0-6.208 4.194-6.772 5.87-6.772 3.915 0 3.635 3.668 3.635 5.643v15.802h6.43v-15.52c0-3.668-.84-7.054-2.796-9.029z"
            />
            <circle fill="#EF4335" cx="104.364" cy="26.091" r="4.659" />
            <path
              d="M27.317 30.31c-2.23 2.23-5.575 3.624-9.198 3.624-6.969 0-11.987-5.576-11.987-13.104S11.15 7.726 18.397 7.726c3.345 0 6.69 1.394 9.2 3.903l.835.836 4.182-4.182-.837-.836c-3.623-3.625-8.362-5.577-13.38-5.577C7.805 1.592 0 9.956 0 20.83s7.805 19.238 18.119 19.238c5.017 0 10.035-1.952 13.38-5.576l.836-.837-4.181-4.182-.837.837z"
              fill="#17313B"
            />
          </g>
        </svg>
        <WidgetInfo>
          <RatingText>
            5.0/5.0
            <StarIcon>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
                  fill="#FF3C2C"
                  stroke="#FF3C2C"
                ></path>
              </svg>
            </StarIcon>
            <StarIcon>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
                  fill="#FF3C2C"
                  stroke="#FF3C2C"
                ></path>
              </svg>
            </StarIcon>
            <StarIcon>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
                  fill="#FF3C2C"
                  stroke="#FF3C2C"
                ></path>
              </svg>
            </StarIcon>
            <StarIcon>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
                  fill="#FF3C2C"
                  stroke="#FF3C2C"
                ></path>
              </svg>
            </StarIcon>
            <StarIcon>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
                  fill="#FF3C2C"
                  stroke="#FF3C2C"
                ></path>
              </svg>
            </StarIcon>
          </RatingText>
          <Paragraph>Based on clients reviews</Paragraph>
        </WidgetInfo>
      </Widget>
    </StyledLink>
  )
}

export default ClutchWidget
